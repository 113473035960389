import download from '../assets/images/download.png';
import logo from '../assets/images/heartynote-logo.png';

const Header = (isLanding) => (
  <div className="header">
    <div className="header-content">
        <a href="/" className="brand">
          {/* <h2>Heartynote</h2> */}
          <img src={logo}/>
        </a>
        <div className="options">

            <a href="https://play.google.com/store/apps/details?id=com.heartynote.mobileapp.heartynote">
              <img src={download}/>
            </a>

            {/* <a href="/login" className="blog">Login</a> */}

            <a href="http://blog.heartynote.com/2017/09/05/heartynote-team-founders/" className="blog">
              Blog
            </a>

            <a href="http://blog.heartynote.com/2017/09/17/indian-youth-power/" className="blog">
              About
            </a>
        </div>
    </div>
     <style jsx>{`
       .header {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          display: flex;
          justify-content: center;
          padding: 10px 30px;
          padding-top: 30px;
          box-sizing: border-box;
          align-items: center;
          z-index: 50;
          z-index: 9990;
       }

       .blog {
         color: #fff;
         text-decoration: none;
         margin: 0px 20px;
       }

       .options {
         display: flex;
         justify-content: center;
         align-items: center;
         font-weight: normal;
         font-family: Nunito;
       }

       .header-content {
          width: 968px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
       }

       .brand {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #000;
       }

       .brand h2 {
         margin: 0px;
         color: #fff;
         font-size: 29px;
         font-weight: normal;
         font-family: Nunito;
       }

       .options img {
          width: 150px;
          display: none;
       }

       .brand img {
          height: 40px;
          // background: #fff;
          // border-radius: 50%;
          // padding: 7px;
          // border: 1px solid #f0f0f0;
          // margin-right: 11px;
       }

       @media(max-width: 900px) {
          .options img {
            display: block;
          }

          .blog {
            display: none;
          }
       }
     
     `}</style>
  </div>
)

export default Header
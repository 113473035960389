import placeholder from '../assets/images/placeholder.png';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Nov', 'Dec'];

function getRandomIdx(max=1000000) {
    return Math.floor(Math.random() * Math.floor(max));
}

function getRandomColor() {
    let colors = [
        'indianred',
        'orange',
        '#22a0ce',
        'limegreen',
        'goldenrod',
        'darkcyan',
        'violet'
    ]

    return colors[Math.floor(Math.random()*6)];
}

function globalMessage(message, warning) {
    let className = (warning) ? "warning" : "success";
    let timeout = (warning) ? 7500 : 3000;
    let div = document.createElement('div');
    div.className = "global-message " + className;
    div.id = "global-message";
    div.innerHTML = message;
    document.body.appendChild(div);
    setTimeout(() => {
        let message = document.getElementById('global-message');
        message.remove();
    }, timeout);
}

function scrollTop() {
    let documentHeight=window.document.offsetHeight;
    let viewportHeight=window.innerHeight;
    window.scrollTo(0, documentHeight-viewportHeight);
}

const getIndexOfkey = (inputArr, needleObj) => {

    if (Object.prototype.toString.call(needleObj) !== '[object Object]') {
        return "Not a valid needle(object)"
    }

    if (Object.prototype.toString.call(inputArr) !== '[object Array]') {
        return "Not a valid array";
    }

    let idx = -1;
    let needleKey = Object.keys(needleObj)[0];
    let needleValue = needleObj[needleKey];

    for (let i = 0; i < inputArr.length; i++) {
        let val = inputArr[i];
        if (val[needleKey] == needleValue) {
            idx = i;
            break;
        }
    }

    return idx;
};

const shortNumber = (val) => {
    if ((val >= 0) && (val <= 999)) {
        return val;
    }

    if ((val >= 1001) && (val <= 99999)) {

        return (Number(val) / 1000).toString().slice(0, 3).concat('K');
    }

    if ((val >= 100001) && (val <= 999999)) {
        return (Number(val) / 1000).toString().slice(0, 3).concat('K');
    }

    if ((val >= 1000000) && (val <= 99999999)) {
        return (Number(val) / 1000000).toString().slice(0, 4).concat('M');
    }

    if (val >= 100000000) {
        return (Number(val) / 1000000).toString().slice(0, 3).concat('M');
    }
}

const copyToClipboard = str => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =            
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
}

const debounce = (fn, time) => {
    setTimeout(() => {
        fn();
    }, time)     
}

const prepareHeaders = (id = 12 , token) => {
    return  {
        'Hearty-Access-Web': true,
        'Hearty-Access-Id' : id,
        'Hearty-Access-Token' : token
    }
}

function popupModal(data, isConfirm, callback) {
    let {title, body, buttonText} = data;

    let heartyModalContent = document.createElement('div')
    , heartyModal = document.createElement('div')
    , heartyModalWrapper = document.createElement('div')
    , heartyModalHeader = document.createElement('div')
    , heartyModalBody = document.createElement('div')
    , heartyModalFooter = document.createElement('div')
    , heartyCloseButton = document.createElement('button')
    , heartyOkButton = document.createElement('button')

    // Modal
    heartyModal.className = "hearty-modal";

    // Wrapper
    heartyModalWrapper.className = "hearty-modal-wrapper";

    // heartyModalContent
    heartyModalContent.className = "hearty-modal-content";

    // Add Header
    heartyModalHeader.className = "hearty-modal-header";
    heartyModalHeader.innerHTML = title;
    heartyModal.appendChild(heartyModalHeader);

    // Add Body
    heartyModalBody.className = "hearty-modal-body";
    heartyModalBody.innerHTML = body;
    heartyModal.appendChild(heartyModalBody);

    let closeModal = () => {
        heartyModalContent.style.display = "none";
            let modal = document.getElementById('hearty-modal-content');
            modal.remove();
    }

    if (isConfirm) {
        // Close Button
        heartyOkButton.onclick = () => {
            callback(1);
            closeModal();
        }
        heartyOkButton.innerHTML = buttonText || "Ok";

        // Close Button
        heartyCloseButton.onclick = () => {
            callback(0);
            closeModal();
        }
        heartyCloseButton.innerHTML = "Close";
        heartyCloseButton.className = "close";

        // Adding ok button for confirm dialogue
        heartyModalFooter.appendChild(heartyOkButton);
    }
    else {

        // Close Button
        heartyCloseButton.onclick = () => {
            closeModal();
            if (callback) {
                callback(1);
            }
        }
        heartyCloseButton.innerHTML = buttonText || "Ok";
    }


    // Add Footer
    heartyModalFooter.className = "hearty-modal-footer";
    heartyModalFooter.appendChild(heartyCloseButton);
    heartyModal.appendChild(heartyModalFooter);

    heartyModalContent.appendChild(heartyModal);
    heartyModalContent.appendChild(heartyModalWrapper);
    heartyModalContent.id = "hearty-modal-content";
 
    document.body.appendChild(heartyModalContent);
}


function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function removeCookie(name) {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

function homeTabs() {
    return [
        'Notes',
        'Videos',
        'Pics',
        'Recent'
    ]
}

function placeholderLazy(className) {
    return <img src={placeholder} className={className}/>
 }

function getCaretPosition(editableDiv) {
    let caretPos = 0,
        sel, range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
            caretPos = range.endOffset;
        }
        }
    } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
        }
    }
    return caretPos;
}

function setCaretPosition(elem, pos) {
    let range = document.createRange();
    let sel = window.getSelection();
    range.setStart(elem.childNodes[0], pos);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
}

function getCurrentDate() {
    let date = new Date();
    return `${date.getDate()} ${months[date.getMonth()]}`;
}

function getCurrentDay() {
    let date = new Date();
    return `${date.getDate()}`;
}

function getCurrentMonth() {
    let date = new Date();
    return `${months[date.getMonth()]}`;
}

export {
    getRandomIdx,
    globalMessage,
    scrollTop,
    getIndexOfkey,
    shortNumber,
    copyToClipboard,
    debounce,
    prepareHeaders,
    getRandomColor,
    setCookie,
    removeCookie,
    homeTabs,
    popupModal,
    placeholderLazy,
    getCaretPosition,
    setCaretPosition,
    getCurrentDate,
    getCurrentDay,
    getCurrentMonth
}



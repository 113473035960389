import React from 'react';

import logo from './assets/images/logo.png';
import download from './assets/images/download.png'
import status from './assets/images/hearty-status.png';

import tvscreen from './assets/images/hearty-tv.png';
import hub from './assets/images/hub.png';
import videoPlay from './assets/images/video_play.png';
import view from './assets/images/view.png';

import landing3 from './assets/images/landing1.jpg';
import landing2 from './assets/images/landing2.jpg';
import landing1 from './assets/images/landing3.jpg';

import landinglazy3 from './assets/images/landinglazy1.jpg';
import landinglazy2 from './assets/images/landinglazy2.jpg';
import landinglazy1 from './assets/images/landinglazy3.jpg';
import placeholder from './assets/images/placeholder.png';


import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { getRandomIdx, shortNumber } from './helpers/utils.js';
// import { URL, API_TRENDING } from '../helpers/config';

import FancyImageLoader from './components/lazyLoad.js';

import "./assets/index.scss";
import "./assets/trending.scss";
class App extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            trending : []
        }
    }

  componentDidMount() {
    //this.loadProfile();
    // this.loadTrending();
    this.loadAnim();
    // setTimeout(() => {
    //     let trend = document.getElementById('trending-body');
    //     let pos = 0; 
    //     this.interval = setInterval(() => { pos += 2; if (pos > 150) { clearInterval(this.interval) }; trend.scrollLeft = pos; }, 90);
    // }, 1000);
  }

  loadAnim() {
     window.onload = () => {
        let img1 = document.getElementById('landing1');
        let img2 = document.getElementById('landing2');
        let img3 = document.getElementById('landing3');

        img1.className += " anim loaded";
        img2.className += " anim loaded1";
        img3.className += " anim loaded2";
     };
  }

//   loadTrending() {
//     fetch('https://api-dev.heartynote.com/public/trending')
//     .then(res => res.json())
//     .then(data => {
//         let {title, description, response} = data;
//         let notes = null;
//         if (response) {
//             let {hearty_user_trending_feed} = response;
//             if (hearty_user_trending_feed) {
//                 let trending = hearty_user_trending_feed.hearty_note_feed;
//                 this.setState({
//                     trending
//                 })
//             }
//         }
//     })
//   }

  render() {
    // let {title, description, notes} = this.props;
    let {trending} = this.state;
    let trendingNotesContent = [];
    let trendingContentStyle = {};

    let placeholderLazy = (idx) => {
        switch (idx) {
            case 0 : return <img src={placeholder} className="thumb"/>
            case 1 : return <img src={landinglazy1}/> 
            case 2 : return <img src={landinglazy2}/> 
            case 3 : return <img src={landinglazy3}/>      
        }
    }

    if (trending.length > 0) {
        trending.forEach(note => {
            let {thumb, link, type, views} = note;
            let videoPlayContent = null;

            views = shortNumber(views);

            if (type == 'video') {
                videoPlayContent = (
                    <div className="trending-video-wrapper">
                        <span className="views"><img src={view} className="view-icon"/> {views} </span>
                        <img src={videoPlay} className="play-icon"/>
                    </div>
                )
            }

            trendingNotesContent.push(
                <a href={link} className="trending-item" key={getRandomIdx()}>
                    <FancyImageLoader 
                        src={thumb}
                        placeholder={() => placeholderLazy(0)}
                        error={() => placeholderLazy(0)}
                        class="thumb"
                        alt = "Heartynote"></FancyImageLoader>
                    {videoPlayContent}
                </a>
            )
        });

        trendingNotesContent.push(
            <a href="/tv" className="trending-item" key={getRandomIdx()}>
                <img src={placeholder} className="thumb"/>
                <div className="trending-video-wrapper">
                    <h3>  More  </h3>
                </div>
            </a>
        )

        trendingContentStyle = {
            width : (trending.length+1) * 190 + "px"
        }
    }

    return (
        <div className="landing-page">
            <div className="section-main">
                <Header/>
                <div className="main-wrapper"></div>
                <div className="section"> 
                    <div className="preview">
                        <FancyImageLoader
                            src={landing1}
                            placeholder={() => placeholderLazy(1)}
                            error = {() => placeholderLazy(1)}
                            class="landing"
                            id="landing1"
                            alt = "Heartynote"></FancyImageLoader>

                        <FancyImageLoader 
                            src={landing2}
                            placeholder={() => placeholderLazy(2)}
                            error = {() => placeholderLazy(2)}
                            class="landing landing1"
                            id="landing2"
                            alt = "Heartynote"></FancyImageLoader>

                        <FancyImageLoader 
                            src={landing3}
                            placeholder={() => placeholderLazy(3)}
                            error = {() => placeholderLazy(3)}
                            class="landing landing2"
                            id="landing3"
                            alt = "Heartynote"></FancyImageLoader>
                    </div>
                    <div className="branding">
                        <img className="logo" src={logo}/>
                        <h1>Note each & every moment <br/> of your life heartfully</h1>
                        {/* <a href="/login" className="login-btn">Login</a> */}
                        <a href="https://play.google.com/store/apps/details?id=com.heartynote.mobileapp.heartynote"><img className="download" src={download}/></a>
                    </div>
                </div>
            </div>
    
            {/* <div className="section-trending">
                <h2> #HeartyTv  <a href="/tv"><img src={videoPlay}/> Tv </a></h2>
                <div className="trending-body" id="trending-body">
                    <div className="trending-content" style={trendingContentStyle}>
                        {trendingNotesContent}
                    </div>
                </div>
            </div> */}

            <div className="section-content">
                <div className="content-item item-one">
                    <div className="content-item-body">
                        <img src={status} alt="hearty status" title="hearty status" />
                        <div className="item-info">
                            <h1>HeartyStatus</h1>
                            <span className="info">Let hearty people know what you are feeling now or currently occupied with.</span>
                        </div>
                    </div>
                </div>
                <div className="content-item item-two">
                    <div className="content-item-body">
                        <img src={hub} alt="hearty hubs" title="hearty hubs" />
                        <div className="item-info">
                            <h1>HeartyHub</h1>
                            <span className="info">A place to store your notes. Save all your notes in different hubs according to your convenience.</span>
                        </div>
                    </div>
                </div>
                <div className="content-item item-three">
                    <div className="content-item-body">
                        <img src={tvscreen} alt="hearty tv" title="hearty tv" />
                        <div className="item-info">
                            <h1>HeartyTv</h1>
                            <span className="info">A place to watch most interesting videos recommended especially for you.</span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
      )
  }
  
}

export default App;
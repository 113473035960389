let year = (new Date()).getFullYear();

const Footer = (isLanding) => (
    <div className="footer">
        <div className="footer-content">
            <span className="copyright">Heartynote@{year}</span>
            <div className="menu">
                <a href="/privacy_policy.html">Privacy policy</a>
                <a href="https://www.facebook.com/heartynote">Facebook</a>
                <a href="https://www.instagram.com/heartynote.official/">Instagram</a>
                <a href="mailto:support@heartynote.com">Support</a>
            </div>
        </div>
        <style jsx>{`
             .footer {
                display: flex;
                justify-content: center;
                padding: 0px 10px;
                box-sizing: border-box;
                background: black;
            }

            .footer-content {
                max-width: 980px;
                display: flex;
                justify-content: space-between;
                margin: 10px 0px;
                font-size: 13px;
                height: 50px;
                align-items: center;
                width: 100vw;
                flex-wrap: wrap;
            }

            .footer-content .copyright {
                color: #777;
                margin: 0px 6px;
            }

            .menu a {
                margin: 0px 6px;
                text-decoration: none;
                color: #999;
                font-size: 13px;
            }

            .footer-content .menu {
                display: flex;
            }
        `}
        </style>
    </div>
)

export default Footer